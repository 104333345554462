<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
               {{$t('add_a_question')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <form>
                    <v-row>
                        <v-col cols="12"
                        >
                            <v-text-field
                                    :label="$t('question')"
                                    v-model="questionData.questionText"
                                    :error-messages="questionTextErrors"
                                    required
                                          dense
                                            outlined
                                    @input="$v.questionData.questionText.$touch()"
                                    @blur="$v.questionData.questionText.$touch()"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                        >
                            <v-autocomplete
                                    v-model="questionData.questionType"
                                    :items="currentQuestionTypes"
                                    :label="$t('question_type')"
                                    clearable
                                    item-text="value"
                                    item-value="id"
                                            dense
                                            outlined
                                    :error-messages="questionTypeErrors"
                                    required
                                    @input="$v.questionData.questionType.$touch()"
                                    @blur="$v.questionData.questionType.$touch()"
                                    @change="changeQuestionType"
                            ></v-autocomplete>
                        </v-col>
                        <v-col
                                cols="12"
                                v-if="! textOption "
                        >
                            <div class="text-center"> {{$t('options')}}</div>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col
                                        cols="12"
                                        md="6"
                                        v-for="(choiceField, index) in choiceText" :key="index"
                                >
                                    <v-row>
                                        <v-col cols="8">
                                            <v-text-field
                                                    outlined
                                                          dense
                                                    :label="`Option ${index}`"
                                                    v-model="choiceText[index]"
                                                    @keyup.enter="addField"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <v-btn dark
                                                           color="blue darken-1"
                                                           @click="addField"
                                                           x-small
                                                    >
                                                        <v-icon x-small> mdi-plus</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-btn dark
                                                           color="red darken-1"
                                                           @click="removeField(index)"
                                                           x-small
                                                    >
                                                        x
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t("form.cancel")}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t("form.save")}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";
    import {required} from "vuelidate/lib/validators";

    export default {
        name: "createQuestionForm",
        props: ["success"],
        computed: {
            textOption() {
                return this.questionTextFlag === 'text' || this.questionTextFlag === 'date';
            },
            questionTextErrors() {
                const errors = [];
                if (!this.$v.questionData.questionText.$dirty) return errors;
                !this.$v.questionData.questionText.required &&
                errors.push("Question is required");
                return errors;
            },
            questionTypeErrors() {
                const errors = [];
                if (!this.$v.questionData.questionType.$dirty) return errors;
                !this.$v.questionData.questionType.required &&
                errors.push("Question Type is required");
                return errors;
            },
        },
        data() {
            return {
                created: false,
                count: 0,
                questionData: {},
                currentQuestionTypes: [],

                choiceText: [
                    ''
                ],
                questionTextFlag: 'text',
                questionTypes: [
                    { id: 'single', value: 'Single Value', IsNp: false },
                    { id: 'single', value: 'एकल मान', IsNp: true },
                    { id: 'multiple', value: 'Multiple Value', IsNp: false },
                    { id: 'multiple', value: 'बहुमूल्य', IsNp: true },
                    { id: 'text', value: 'Textual', IsNp: false },
                    { id: 'text', value: 'पाठ्य', IsNp: true },
                    { id: 'date', value: 'Date', IsNp: false },
                    { id: 'date', value: 'मिति', IsNp: true },
                ],
                snackbar: {
                    dateMenu: false,
                    servicesData: {
                        data: {},
                        message: "",
                        color: ""
                    },
                }
            };
        },
        mounted() {
            let questionNepali = localStorage.getItem('lang');
            this.currentQuestionTypes = [];
            if (questionNepali == 'ne') {

                this.currentQuestionTypes = this.questionTypes.filter(function (data) {
                    return data.IsNp === true;
                });
            }
            else {
                this.currentQuestionTypes = this.questionTypes.filter(function (data) {
                    return data.IsNp === false;
                });
            }
        },
        methods: {
            changeQuestionType(value) {
                this.questionTextFlag = value
            },
            removeField(choiceField) {
                if (choiceField > 0) {
                    this.choiceText.splice(choiceField, 1)
                }

                console.log(this.choiceText)
            },
            addField() {
                this.choiceText.push('')
                console.log(this.choiceText)
            },
            dialogueClose() {
                this.questionData = {};
                this.$emit("dialogueClose");
            },
            formResponse() {
                this.$emit("formResponse", this.snackbar.servicesData);
                this.dialogueClose();
            },
            submit() {
                this.questionData.ChoiceText = this.choiceText
                this.questionData.divisionID = parseInt(this.$route.params.divisionId)
                this.questionData.projectID = parseInt(this.$route.params.projectId)
                let formData = []
                formData.push(this.questionData)
                console.log('this.questionData', this.questionData)
                axios.post("Survey/InsertIntoSurveyQuestionsAsync", formData).then(response => {
                    if (response.data.success) {
                        this.snackbar.servicesData.data = response.data
                        this.snackbar.servicesData.message = "Question added Successfully"
                        this.snackbar.servicesData.color = "success"
                        this.formResponse()
                    }
                }).catch(response => {
                    this.snackbar.servicesData.data = response.data
                    this.snackbar.servicesData.message = "Error Adding Question, Please Contact Admin"
                    this.snackbar.servicesData.color = "error"
                    this.formResponse()
                });

            },
            clear() {
                this.$v.$reset();
                this.questionData = {};
            },
        },
        validations: {
            questionData: {
                questionText: {required},
                questionType: {required},

            }
        },
    };
</script>

<style scoped>
</style>