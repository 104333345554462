<template>
    <v-content>
        <div class="container">
            <v-breadcrumbs :items="snackbar.breadcrumbItems" large></v-breadcrumbs>
            <div slot="table-actions">
                <div class="my-1">
                    <v-btn color="primary" @click.stop="addModal">{{$t('add_a_question')}}</v-btn>
                </div>
            </div>
            <vue-good-table :columns="columns"
                            :rows="rows"
                            mode="remote"
                            :totalRows="questionData.totalRecords"
                            :pagination-options="{ enabled: true,mode: 'records' }"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field == 'questionText'">
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header> {{ props.row.questionText }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div v-if="props.row.choices">
                                        <div v-for="(item, index) in props.row.choices" :key="index">
                                            <div class="field_container">
                                                <v-btn dark
                                                       outlined
                                                       color="red darken-1"
                                                       @click="removeField(item)">
                                                    x
                                                </v-btn>
                                                <v-text-field outlined
                                                              dense
                                                              v-model="item.choiceText"
                                                              @input="fieldChange( props.row)"></v-text-field>
                                            </div>
                                        </div>
                                        <v-text-field outlined
                                                      dense
                                                      v-model="newAnswerData"
                                                      @input="fieldChange(props.row)"
                                                      v-if="newAnswerField[props.row.questionID]"></v-text-field>
                                        <v-spacer></v-spacer>
                                        <v-btn rounded color="primary" x-small dark class="mr-1"
                                               v-if="! newAnswerField[props.row.questionID]"
                                               @click="addNewAnswerField(props.row.questionID)">
                                            Add Options
                                        </v-btn>
                                        <v-btn rounded color="success" x-small dark
                                               v-if="answerChanged[props.row.questionID]
                                               || newAnswerChanged[props.row.questionID]"
                                               @click="submitTableForm(newAnswerChanged[props.row.questionID] || false)">
                                            Save
                                        </v-btn>
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>
                    <span v-else-if="props.column.field == 'questionType'">
                        {{props.row.questionType.toUpperCase()}}
                    </span>
                    <span v-else-if="props.column.field == 'actions'">
                        <v-icon small class="mr-2" @click.stop="editItem(props.row)">edit</v-icon>
                        <v-icon small @click="deleteItem(props.row)">delete</v-icon>
                    </span>
                </template>
            </vue-good-table>
            <v-dialog
                    v-model="snackbar.formModal"
                    hide-overlay
                    transition="dialog-bottom-transition"
                    persistent
                    fullscreen
            >
                <v-card>
                    <QuestionForm @dialogueClose="dialogueClose"
                                  @formResponse="formResponse"
                                  v-if="snackbar.createFormComponent"
                    ></QuestionForm>
                    <QuestionEditForm @dialogueClose="dialogueClose"
                                      @formResponse="formResponse"
                                      v-if="snackbar.editFormComponent"
                                      :editItemIndex="questionData.editItemIndex"
                    ></QuestionEditForm>
                </v-card>
            </v-dialog>
            <v-snackbar
                    v-model="snackbar.snackbar"
                    :color="snackbar.color"
                    :timeout="snackbar.timeout"
                    top
            >
                {{ snackbar.text }}
                <v-btn
                        dark
                        text
                        @click="snackbar.snackbar = false"
                >
                    Close
                </v-btn>
            </v-snackbar>
            <v-dialog v-model="snackbar.dialogDelete" max-width="500px">
                <deleteListData :deleteFormData="questionData.deleteFormData"
                                @formResponse="onResponseDelete"></deleteListData>
            </v-dialog>
        </div>
    </v-content>
</template>

<script>
    import axios from "axios";
    import QuestionForm from "./QuestionForm";
    import QuestionEditForm from "./QuestionEditForm";
    import deleteListData from "@/components/deleteModal";
    /* import {mapGetters} from "vuex";*/

    export default {
        name: "QuestionList",
        components: {
            'QuestionForm': QuestionForm,
            'QuestionEditForm': QuestionEditForm,
            'deleteListData': deleteListData
        },
        data() {
            return {
                columns: [
                    {
                        label: "s_no",
                        field: "countIndex"
                    },
                    {
                        label: 'question',
                        field: 'questionText',
                        width: '75%'
                    },
                    {
                        label: 'question_type',
                        field: 'questionType',
                        width: '10%'
                    },
                    {label: "actions", field: "actions", width: '5%'}
                ],
                rows: [],
                questionTextFlag: 'text',
                questionTypes: [
                    {id: 'single', value: 'Single Value'},
                    {id: 'multiple', value: 'Multiple Value'},
                    {id: 'text', value: 'Textual'},
                    {id: 'date', value: 'Date'},
                ],
                inputData: {},
                answerChanged: [],
                newAnswerChanged: [],
                newAnswerData: null,
                newAnswerField: [],
                questionData: {
                    totalRows: 0,
                    editItemIndex: null,
                    deleteFormData: {}
                },

                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "questionID",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    dialogDelete: false,
                    breadcrumbItems: [
                        {
                            text: 'Dashboard',
                            disabled: false,
                            to: '/SurveyDashboard',
                            exact: true
                        },
                        {
                            text: 'Provinces',
                            disabled: false,
                            to: {name: 'SurveyProvinceCategories'},
                            exact: false
                        },
                        {
                            text: 'Divisions',
                            disabled: false,
                            to: {
                                name: 'SurveyProvinceDivisions',
                                params: {
                                    provinceId: this.$route.params.provinceId
                                }
                            },
                            exact: false
                        },
                        {
                            text: 'Projects',
                            disabled: false,
                            to: {
                                name: 'SurveyProvinceDivisionProjects',
                                params: {
                                    provinceId: this.$route.params.provinceId,
                                    divisionId: this.$route.params.divisionId,
                                }
                            },
                            exact: false
                        },
                        {
                            text: 'Question List',
                            disabled: true,
                        },
                    ],
                    servicesData: {
                        data: {},
                        message: "",
                        color: ""
                    },
                }
            };
        },
        methods: {
            removeField(item) {
                console.log(item.choiceID)
                axios.post('Survey/DeleteSurveyChoiceByIDAsync/' + item.choiceID, {})
                    .then((response) => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Data Deleted Successfully"
                        this.servicesData.color = "success"
                        this.loadItems()
                    })
                    .catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.loadItems()

                    });

            },
            fieldChange(props) {
                this.inputData = {}
                this.answerChanged = []
                this.answerChanged[props.questionID] = true
                this.inputData = props
                if (this.newAnswerData) {
                    this.newAnswerChanged[props.questionID] = true
                }
                console.log('newInputData: ', this.inputData)
            },
            addNewAnswerField(index) {
                this.newAnswerData = null
                this.newAnswerField = []
                console.log('this.newAnswerData :', this.newAnswerData)
                this.newAnswerField[index] = true

            },

            submitTableForm(newAnswerFlag) {

                if (newAnswerFlag) {
                    this.inputData.choices.push({choiceID: 0, choiceText: this.newAnswerData})
                }
                console.log('submitInputData: ', this.inputData)
                axios.post("Survey/UpdateSurveyQuestionsAsync", this.inputData).then(response => {
                    if (response.data.success) {
                        this.snackbar.servicesData.data = response.data
                        this.snackbar.servicesData.message = "Answer Changed Successfully"
                        this.snackbar.servicesData.color = "success"
                        this.formResponse(this.snackbar.servicesData)
                    }
                }).catch(response => {
                    this.snackbar.servicesData.data = response.data
                    this.snackbar.servicesData.message = "Error Changing, Please Contact Admin"
                    this.snackbar.servicesData.color = "error"
                    this.formResponse(this.snackbar.servicesData)
                });
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                console.log('item:', item)
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.questionData.editItemIndex = item.questionID
            },

            deleteItem(item) {
                this.snackbar.dialogDelete = true;
                console.log(item)
                this.questionData.deleteFormData.url = "Survey/DeleteSurveyQuestionsByIDAsync/" + item.questionID;
                this.loadItems();
            },

            onResponseDelete(responseObj) {
                this.snackbar.dialogDelete = false;
                this.snackbar.color = responseObj.color ? responseObj.color : 'red'
                this.snackbar.text = responseObj.message ? responseObj.message : 'Server Error'
                this.snackbar.snackbar = true
                this.loadItems();
            },

            formResponse(responseObj) {
                this.snackbar.color = responseObj.color ? responseObj.color : 'red'
                this.snackbar.text = responseObj.message ? responseObj.message : 'Server Error'
                this.snackbar.snackbar = true
                this.answerChanged = {}
                this.inputData = {}
                this.newAnswerChanged = []
                this.newAnswerData = null
                this.newAnswerField = []
                /*this.newAnswerChanged = []
                this.newAnswerData = null
                this.newAnswerField = []*/
                this.loadItems();
            },

            dialogueClose() {
                this.questionData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
                this.loadItems()
            },

            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    projectID: parseInt(this.$route.params.projectId),
                };
                const questionData = await axios.post('Survey/GetSurveyQuestionsListAsync', param)
                this.rows = questionData.data
                this.snackbar.loading = false;
            },
        },
        mounted() {
            this.loadItems()
        },
    }
</script>

<style scoped lang="scss">
    .field_container {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 2px;
    }
</style>
